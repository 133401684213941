import classnames from 'classnames';
import React from 'react';
import { connect } from 'react-redux';
import { compose, withHandlers } from 'recompose';
import { InformerIcon } from '../../../components/icons/informer-icon';
import { withFetchFAQData, withShowFAQ } from '../../../pages/dashboard/widgets/future-fit-v2/decorators';
import { getFAQ } from '../../../store/reducers/faq';
import { toggleModal } from '../../../store/reducers/modals';
import styles from '../../../styles/widgets/feature-fit-v2/ff-high-priority-goals.module.scss';
import { HPG_LIST_HEADERS } from '../../../helpers/future-fit/constants';

const GoalsListHeaderComponent = ({ toggleScoreInfo, toggleCompletedActionsInfo, listHeaderConfig }) => {
  return (
    <div className={styles['goal-row-header']}>
      <div className={classnames(styles['goal-row-header-text'], styles['goal-row-header-label'])}>
        {listHeaderConfig?.descriptionColumn ?? HPG_LIST_HEADERS.descriptionColumn}
      </div>
      <div className={classnames(styles['goal-row-header-detail'], styles['goal-row-header-label'])}>
        <div className={styles['goal-row-header-score']}>
          {listHeaderConfig?.scoreColumn ?? HPG_LIST_HEADERS.scoreColumn}
          <InformerIcon className={styles['action-score-icon']} onClick={toggleScoreInfo} />
        </div>
        <div className={classnames(styles['goal-row-header-action'], styles['goal-row-header-label'])}>
          {listHeaderConfig?.actionsColumn ?? HPG_LIST_HEADERS.actionsColumn}
          <InformerIcon className={styles['action-score-icon']} onClick={toggleCompletedActionsInfo} />
        </div>
      </div>
    </div>
  );
};

export const GoalsListHeader = compose(
  connect(null, { toggleModal, getFAQ }),
  withShowFAQ,
  withFetchFAQData,
  withHandlers({
    toggleScoreInfo: ({ showInfo }) => {
      showInfo('GoalsScore');
    },
    toggleCompletedActionsInfo: ({ showInfo }) => {
      showInfo('GoalsCompletedActions');
    }
  })
)(GoalsListHeaderComponent);
