import React from 'react';
import PropTypes from 'prop-types';
import { List } from '../../../components/list';
import GoalItem from '../../../pages/dashboard/widgets/future-fit-v2/components/goal-item';
import styles from '../../../styles/templates/content-feed-template.module.scss';
import { ConfigService } from '../../../services/config-service';

const GoalsList = React.memo(({ goalsItems, emptyText }) => {
  if (!goalsItems.length) {
    return (
      <div className={styles.centered}>
        <span className={styles.emptyText}>{emptyText}</span>
      </div>
    );
  }
  const { buttonIcon = null, guidanceTitle = '' } = ConfigService.get('GOALS.goalsPage', {});

  return (
    <List>
      {goalsItems.map(({ slug, heading, subHeading, activities, userAttribute }) => (
        <GoalItem
          key={slug}
          to={`/goals/${slug}`}
          heading={heading}
          subHeading={subHeading}
          guidanceTitle={guidanceTitle}
          activities={activities}
          icon={buttonIcon}
          userAttribute={userAttribute}
        />
      ))}
    </List>
  );
});

GoalsList.propTypes = {
  goalsItems: PropTypes.arrayOf(PropTypes.shape(GoalItem.propTypes)),
  emptyText: PropTypes.string
};

GoalsList.displayName = 'GoalsList';

export { GoalsList };
