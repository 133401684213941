import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { compose, withHandlers, withPropsOnChange, withState } from 'recompose';
import { ButtonWithIcon } from '../../components/buttons/button-with-icon';
import { isModuleCompleted } from '../../helpers/custom-page/is-completed';
import styles from '../../styles/components/checklist-category.module.scss';
import { ConfigService } from '../../services/config-service';
import { sliceCheckListItems, sortCheckListItemsByStatus } from './helpers';
import { ChecklistItem } from './item';

export const ChecklistItemsWrapper = React.memo(({ className, children }) => (
  <ul className={classnames(styles['list-items'], className)}>{children}</ul>
));

const ItemsListComponent = React.memo(({ collapsed, items, toggleCollapse, displayButton }) => {
  return (
    <div className={styles['list-wrapper']}>
      <ChecklistItemsWrapper>
        {items.map(item => (
          <ChecklistItem key={item.link} item={item} />
        ))}
      </ChecklistItemsWrapper>
      {displayButton ? (
        <ButtonWithIcon onClick={toggleCollapse}>{collapsed ? 'Show More' : 'Show Less'}</ButtonWithIcon>
      ) : null}
    </div>
  );
});

ItemsListComponent.displayName = 'CollapsingItemsListComponent';

export const ChecklistItems = compose(
  withPropsOnChange([], () => {
    const statusSortEnabled = ConfigService.get('CHECKLIST.statusSortEnabled', false);

    return { statusSortEnabled };
  }),
  connect(
    ({ statusData }, { maxItems, listType, items, statusSortEnabled }) => ({
      displayButton: !!maxItems && maxItems < items.length,
      items: statusSortEnabled
        ? sortCheckListItemsByStatus(
            items.map(item => ({ ...item, isCompleted: isModuleCompleted(statusData, item.statusMappingDetails) }))
          )
        : items
    }),
    null
  ),
  withState('collapsed', 'setCollapsed', ({ displayButton }) => displayButton),
  withPropsOnChange(['items', 'collapsed'], ({ collapsed, items, maxItems }) => ({
    items: sliceCheckListItems({ arrayOfItems: items, limit: maxItems, condition: collapsed })
  })),
  withHandlers({
    toggleCollapse:
      ({ setCollapsed }) =>
      () =>
        setCollapsed(prev => !prev)
  })
)(ItemsListComponent);

ChecklistItems.propTypes = {
  items: PropTypes.array,
  maxItems: PropTypes.number
};

ChecklistItems.displayName = 'ChecklistItems';
