import classnames from 'classnames';
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { compose, withPropsOnChange } from 'recompose';
import { ObjectShim } from '@packages/helpers/core/shims/object-shim';
import { isUndefined } from '@packages/helpers/core/common';
import { ListItem } from '../../../../../components/list';
import styles from '../../../../../styles/widgets/feature-fit-v2/ff-high-priority-goals.module.scss';
import { ButtonWithIcon } from '../../../../../components/buttons/button-with-icon';
import { calculateUncompletedActivities, getBadgeState } from '../../../../../helpers/guidance';
import { KPIIndicator } from './kpi-indicator';

const Component = ({
  to,
  heading,
  subHeading,
  guidanceTitle,
  icon,
  score,
  isCompleted,
  badgeValue,
  showKPI = false
}) => {
  return (
    <ListItem className={styles.goal}>
      <div className={styles.header}>
        <span className={styles.heading}>{heading}</span>
        <span className={styles.subHeading}>{subHeading}</span>
      </div>
      <div className={styles.details}>
        {showKPI ? (
          <KPIIndicator className={styles.score} value={score} />
        ) : (
          <span className={styles.priority}>Low Priority</span>
        )}
        <div className={styles.action}>
          <ButtonWithIcon className={styles.guidance} to={to} icon={icon} type='diminished'>
            {guidanceTitle}
          </ButtonWithIcon>
          <div className={classnames(styles.badge, { [styles['completed-badge']]: isCompleted })}>
            <span className={styles.badgeText}>{badgeValue}</span>
          </div>
        </div>
      </div>
    </ListItem>
  );
};

Component.displayName = 'GoalItem';

Component.propTypes = {
  to: PropTypes.string.isRequired,
  heading: PropTypes.string,
  subHeading: PropTypes.string,
  guidanceTitle: PropTypes.string,
  userAttribute: PropTypes.string,
  showKPI: PropTypes.bool,
  icon: PropTypes.string,
  score: PropTypes.number,
  isCompleted: PropTypes.bool,
  badgeValue: PropTypes.string
};

const mapStateToProps = ({ userAttributes }, { userAttribute }) => {
  const score = ObjectShim.getNested(userAttributes, userAttribute);
  // Specific verification on 'undefined' means that goal is a high priority, but probably hasn't a score for now ('null')
  const showKPI = !isUndefined(score);

  return { score, showKPI };
};

const GoalItem = compose(
  connect(mapStateToProps),
  withPropsOnChange(['activities', 'score'], ({ activities, score }) => {
    const uncompletedActivitiesQuantity = calculateUncompletedActivities(activities);

    const { isCompleted, badgeValue } = getBadgeState({ uncompletedActivitiesQuantity, activities, score });

    return {
      uncompletedActivitiesQuantity,
      isCompleted,
      badgeValue
    };
  })
)(Component);

export default GoalItem;
