import { ArrayShim } from '@packages/helpers/core/shims/array-shim';
import uuid from 'uuid';
import { parse } from 'query-string';
import { ObjectShim } from '@packages/helpers/core/shims/object-shim';
import { isHighPriorityGoal } from '../../helpers/guidance';

export const createKPIListItems = ({ totalScores, score, guidance }) => {
  return ArrayShim.range(0, Number(totalScores))
    .map((_, i) => ({
      key: uuid(),
      checked: i + 1 <= score
    }))
    .map((step, i) => ({
      ...step,
      label: guidance[`label${i + 1}`]
    }));
};

export const filterActivities = ({ allActivities, allowedActivities }) => {
  const filteredActivities = [];

  for (const activity of allActivities) {
    const isAllowedActivity = Object.hasOwn(allowedActivities, activity.key);

    if (isAllowedActivity) {
      activity.activityStatusKey = allowedActivities[activity.key];
      filteredActivities.push(activity);
    }
  }

  return filteredActivities;
};

export const parseURLParams = search => {
  const { date, brokerName, score, activities: encodedActivities } = parse(search);
  const allowedActivities = JSON.parse(encodedActivities);

  return { date, brokerName, score: Number(score), allowedActivities };
};

const TOTAL_SCORES = 3;

export const isGuidanceExternal = search => !!search;

export const getGuidanceProps = ({ guidance, userAttributes, search }) => {
  const isExternal = isGuidanceExternal(search);
  const basicProps = { totalScores: TOTAL_SCORES, isExternal, isHPG: true };
  const { userAttribute, activities: allActivities } = guidance;

  if (isExternal) {
    const urlParams = parseURLParams(search);
    const { allowedActivities } = urlParams;
    const activities = filterActivities({ allActivities, allowedActivities });

    return { ...basicProps, ...urlParams, activities };
  }

  //userAttribute - is the path where the guidance score might be stored - set in guidance section in admin
  const score = ObjectShim.getNested(userAttributes, userAttribute);
  const isHPG = isHighPriorityGoal({ userAttributes, userAttribute });

  return { ...basicProps, activities: allActivities, score, isHPG };
};
