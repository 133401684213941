import { createAction, createReducer } from '../utils';
import {
  parseStatuses,
  isValid,
  hasHigherOrder,
  parseStatusesToSingleDimension,
  areStatusesEqual
} from '../../helpers/status-data';
import { UserService } from '../../services/user-service';
import { statusData as statusDataApi } from '../../api/status-data';
import { isModuleCompletedPlain } from '../../helpers/custom-page/is-completed';
import { LOG_OUT_SUCCESS } from './account/action-types';

const GET_STATUS_DATA_REQUEST = 'GET_STATUS_DATA_REQUEST';
export const GET_STATUS_DATA_SUCCESS = 'GET_STATUS_DATA_SUCCESS';
const GET_STATUS_DATA_FAILURE = 'GET_STATUS_DATA_FAILURE';

export const SEND_STATUS_DATA_SUCCESS = 'SEND_STATUS_DATA_SUCCESS';
export const SEND_STATUS_DATA_FAILURE = 'SEND_STATUS_DATA_FAILURE';

export const ACTIVITY_COMPLETED = 'ACTIVITY_COMPLETED';

export const getStatusData = () => async dispatch => {
  await dispatch(
    createAction(
      {
        request: GET_STATUS_DATA_REQUEST,
        success: GET_STATUS_DATA_SUCCESS,
        failure: GET_STATUS_DATA_FAILURE
      },
      api => api.statusData.getStatusData
    )()
  );
};

export const sendStatus = (moduleName, moduleStatus, cb, additionalData) => async (dispatch, getState) => {
  if (!UserService.hasAccount()) {
    const {
      activityKey,
      point,
      status,
      errorMsg = null
    } = await dispatch(
      createAction(
        {
          success: SEND_STATUS_DATA_SUCCESS,
          failure: SEND_STATUS_DATA_FAILURE
        },
        api => api.statusData.sendStatusData
      )({
        activityKey: moduleName,
        activityStatusKey: moduleStatus,
        data: additionalData
      })
    );

    const { userAttributes, statusData } = getState();

    const dependencies = [activityKey, status, statusData.parsedStatuses];

    if (isValid(...dependencies) && (hasHigherOrder(...dependencies) || areStatusesEqual(...dependencies))) {
      const dateTime = new Date().toISOString();
      //Trigger points calculation or show message and to update dateTime when activity is re-completed
      dispatch({
        type: SEND_STATUS_DATA_SUCCESS,
        payload: {
          moduleName: activityKey,
          status: { ...status, dateTime },
          points: point,
          promotions: userAttributes.consents.promotions
        }
      });
    }

    if (isModuleCompletedPlain(status)) {
      // call to trigger action in middleware when activity completed
      dispatch({
        type: ACTIVITY_COMPLETED,
        payload: {
          moduleName: activityKey
        }
      });
    }

    if (cb && !errorMsg) {
      cb();
    }
  }
};

export const removeStatus = (moduleName, status) => async dispatch => {
  !UserService.hasAccount() &&
    (await statusDataApi.removeStatusData({
      activityKey: moduleName,
      activityStatusKey: status
    }));

  dispatch({ type: SEND_STATUS_DATA_SUCCESS, payload: { moduleName, status: null } });
};

const initialState = { statuses: {}, parsedStatuses: {}, plainStatuses: {}, loading: true, error: false };

export const statusData = createReducer(
  { ...initialState },
  {
    [GET_STATUS_DATA_REQUEST]: state => ({ ...state, loading: true }),
    [GET_STATUS_DATA_SUCCESS]: (state, action) => ({
      ...state,
      plainStatuses: parseStatusesToSingleDimension(action.payload),
      statuses: action.payload,
      parsedStatuses: parseStatuses(action.payload),
      loading: false
    }),
    [GET_STATUS_DATA_FAILURE]: state => ({
      ...state,
      loading: false,
      error: true
    }),
    [SEND_STATUS_DATA_SUCCESS]: (state, { payload }) => ({
      ...state,
      plainStatuses: {
        ...state.plainStatuses,
        ...(payload.moduleName && { [payload.moduleName]: payload.status?.key })
      },
      parsedStatuses: {
        ...state.parsedStatuses,
        ...(payload.moduleName && { [payload.moduleName]: payload.status })
      }
    }),
    [SEND_STATUS_DATA_FAILURE]: state => ({ ...state, error: true }),
    [LOG_OUT_SUCCESS]: () => ({ ...initialState })
  }
);
