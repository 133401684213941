import { ObjectShim } from '@packages/helpers/core/shims/object-shim';
import { getTwoDimensionalArrayIterator } from '../array';
import { evaluateCondition } from './display-condition';

/* Will be removed as soon as BE will have implemented own mapping */
const DYNAMIC_ITEM_TO_STATIC_ITEM_MAP = {
  name: 'labelText'
};

const DYNAMIC_TO_STATIC_MAP = {
  checklistItems: 'items',
  isExpanded: 'expanded'
};

const DYNAMIC_PROP_MAP = {
  checklistItems: (section = []) => {
    return section.map(item =>
      Object.keys(item).reduce(
        (acc, key) => ({
          ...acc,
          [DYNAMIC_ITEM_TO_STATIC_ITEM_MAP[key] ?? key]: item[key]
        }),
        {}
      )
    );
  }
};
export const mapChecklist = (checklist = []) => {
  return checklist.map(section =>
    Object.keys(section).reduce((acc, key) => {
      return {
        ...acc,
        [DYNAMIC_TO_STATIC_MAP[key] ?? key]: DYNAMIC_PROP_MAP[key] ? DYNAMIC_PROP_MAP[key](section[key]) : section[key]
      };
    }, {})
  );
};
/* ---- */
const getVisibleChecklistItem = async (items, defaultValue, userState) => {
  for (const item of items) {
    const { displayCondition } = item;

    const shouldDisplayItem = await evaluateCondition(displayCondition, userState);

    if (!displayCondition || shouldDisplayItem) {
      return item;
    }
  }
};
export const getChecklistFirstVisibleItem = async (checklist = {}, defaultValue, userState) => {
  for (const group of checklist) {
    const { displayCondition } = group;

    const shouldDisplayGroup = await evaluateCondition(displayCondition, userState);

    if (!displayCondition || shouldDisplayGroup) {
      return await getVisibleChecklistItem(group.items, defaultValue, userState);
    }
  }

  return ObjectShim.getNested(checklist, '0.items.0', defaultValue);
};

export const getChecklistItemBy = async (checklist, callback, defaultValue, userState) => {
  const items = getTwoDimensionalArrayIterator(checklist, 'items');

  for (const item of items) {
    if (callback(item)) {
      return item;
    }
  }

  return await getChecklistFirstVisibleItem(checklist, defaultValue, userState);
};

export const getNextChecklistItemBy = async (checklist, callback, defaultValue, userState) => {
  const items = getTwoDimensionalArrayIterator(checklist, 'items');

  let match = false;

  for (const item of items) {
    // if previous iteration was success return current item
    if (match) {
      return item;
    }

    // store the match of current iteration
    match = callback(item);
  }

  return await getChecklistFirstVisibleItem(checklist, defaultValue, userState);
};

export const get2DChecklistItems = checklist => {
  const items = getTwoDimensionalArrayIterator(checklist, 'items');
  const checklistItems = [];

  for (const item of items) {
    if (item) {
      checklistItems.push(item);
    }
  }

  return checklistItems;
};

export const getChecklistItemIconProps = statusStyle => {
  const iconName = `checklist-indicator-${statusStyle}`;
  const iconStyle = {
    '--app-custom-checklist-item-bg-colour': `var(--checklist-item-checkbox-${statusStyle}-bg-colour)`,
    '--app-custom-checklist-item-icon-colour': `var(--checklist-item-checkbox-${statusStyle}-icon-colour)`,
    '--app-custom-checklist-item-border': `var(--checklist-item-checkbox-${statusStyle}-border)`
  };

  return { iconName, iconStyle };
};
