import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { parse } from 'query-string';
import { connect, shallowEqual, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import useMountEffect from '@packages/hooks/core/use-mount-effect';
import { buildGuidanceParams, DEFAULT_CATEGORY_KEY, filterGuidances } from '../../helpers/guidance';
import { defaultCategory, formatDataDictionaryCategories, formatFeedCategories } from '../../helpers/feed';
import { CARD_LOCATION_PAGE_TYPES } from '../../helpers/repeaters/constants';
import { ConfigService } from '../../services/config-service';
import { getStaticData } from '../../store/reducers/static-data';
import GoalsTemplate from '../../templates/goals';
import { getGuidanceList } from '../../store/reducers/guidance';

const GoalsPage = props => {
  const { getGuidanceList, getCategoriesList } = props;
  const { push } = useHistory();
  const { search } = useLocation();
  const params = useMemo(() => parse(search), [search]);

  const {
    pageName,
    pageHeader,
    emptyStateText,
    emptyCategoryText,
    isDisplayItemSubTitle,
    dataDictionaryIdentifier,
    listHeaders
  } = ConfigService.get('GOALS', {});

  const categoriesLoading = useSelector(state => state.staticData.loading[dataDictionaryIdentifier], shallowEqual);
  const categoriesOptions = useSelector(state => {
    const categoryLabels = formatDataDictionaryCategories(state.staticData[dataDictionaryIdentifier]);
    const formattedCategories = formatFeedCategories(categoryLabels);

    return [defaultCategory, ...formattedCategories];
  }, shallowEqual);

  const guidanceItems = useSelector(state => state.guidance.list, shallowEqual);
  const guidanceLoading = useSelector(state => state.guidance.loading, shallowEqual);

  const [category, setCategory] = useState(DEFAULT_CATEGORY_KEY);
  const [guidanceList, setGuidances] = useState(guidanceItems);

  useEffect(() => {
    const foundedCategory = categoriesOptions.find(c => c.value.key === decodeURIComponent(params?.categories));
    setCategory(foundedCategory?.value.key || DEFAULT_CATEGORY_KEY);
  }, [categoriesOptions, params?.categories]);

  // Filter guidance list on current category
  useEffect(() => {
    setGuidances(filterGuidances({ guidanceItems, currentCategory: category }));
  }, [category, guidanceItems, setGuidances]);

  const handleChangeCategory = useCallback(
    () =>
      ({ key }) => {
        push(`?${buildGuidanceParams({ category: key })}`);
      },
    [push]
  );

  // Fetching categories options for a dropdown and content items.
  useMountEffect(() => {
    const fetchData = async () => {
      await getCategoriesList(dataDictionaryIdentifier);
      getGuidanceList();
    };

    fetchData();
  });

  return (
    <GoalsTemplate
      emptyCategoryText={emptyCategoryText}
      emptyStateText={emptyStateText}
      pageName={pageName}
      pageHeader={pageHeader}
      goalsItems={guidanceList}
      goalsLoading={guidanceLoading || categoriesLoading}
      selectedCategory={category}
      categoriesOptions={categoriesOptions}
      isDisplayItemSubTitle={isDisplayItemSubTitle}
      handleChangeCategory={handleChangeCategory}
      pageType={CARD_LOCATION_PAGE_TYPES.ContentFeed}
      listHeaders={listHeaders}
    />
  );
};

GoalsPage.propTypes = {
  getGuidanceList: PropTypes.func.isRequired,
  getCategoriesList: PropTypes.func.isRequired
};

GoalsPage.displayName = 'GoalsPage';

export default connect(null, { getCategoriesList: getStaticData, getGuidanceList })(GoalsPage);
