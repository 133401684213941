import { asTemplate } from '../components/layout/with-layout';
import GoalsPage from '../pages/goals';
import GuidancePage from '../pages/guidance';

const GOALS = 'goals';

export const goalsRoutes = [
  {
    to: '/goals',
    component: asTemplate(GOALS)(GoalsPage)
  },
  {
    to: '/goals/:slug',
    component: asTemplate(GOALS)(GuidancePage)
  }
];
